import {FunctionSpec} from "@graphql/graphql.ts";
import {ProgramCounter} from "@util/types.ts";
import React, {memo, ReactNode, useState} from "react";
import {useApolloClient} from "@apollo/client";
import {useConfirmationDialog} from "@providers/confirmation-dialog.tsx";
import {FunctionSpecEditor} from "@util/function-spec-editing.tsx";
import DataObjectIcon from "@mui/icons-material/DataObject";
import {deleteFunctionSpec} from "@util/queries.tsx";
import DeleteIcon from "@mui/icons-material/Delete";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import {HelpCircle} from "@components/HelpCircle.tsx";
import {FunctionTableEditor} from "@components/FunctionTableEditor.tsx";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import EditableLabel from "@util/editable-label.tsx";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {ExpandableCell} from "@components/ExpandableCell.tsx";
import {
  DraggableProvided,
  DraggableProvidedDraggableProps,
} from "@hello-pangea/dnd";

type FunctionSpecCardProps = {
  functionSpec: FunctionSpec;
  inlined?: boolean;

  // binaryID is either the binary to use when figuring out the function's debug
  // info, or, if a binary had not been selected, a function that prompts the
  // selection of the binary. Once the binary is selected, this component should
  // be re-rendered with binaryID being a string.
  binaryID: string | (() => void);

  // If binaryID is specified, then a program counter can also be specified. If
  // it is, it will dictate the availability of variables.
  pc?: ProgramCounter;

  // If set, a header with the function's name, a delete button, and the move
  // up/down buttons is shown.
  showHeader?: boolean;

  // defaultExpanded controls which of the function's specs start off as
  // expanded. If not set, both specs start off as collapsed. If defaultExpanded
  // refers to a spec that doesn't exist, it has no effect.
  defaultExpanded?: "snapshot" | "event" | "both";

  // asTableRow, if set, makes the component render as a table row.
  asTableRow?: {
    dragHandle: ReactNode;
    rowRef: DraggableProvided["innerRef"];
    draggableProps: DraggableProvidedDraggableProps;
  };
};

// FunctionSpecCard is the editor for one function spec, including both the
// function's frames table spec and the function start event spec. For each of
// these two, it allows selecting variables to collect and defining columns.
//
// FunctionSpecCard contains the logic for updating the spec. It deals with
// rendering a header, and then it uses FunctionTableEditor twice for rendering
// the spec -- once for the frames table (if any) and once for the function
// start events table (if any).
export function FunctionSpecCard(
  props: FunctionSpecCardProps,
): React.JSX.Element {
  const {functionSpec} = props;
  const client = useApolloClient();

  const showConfirmationDialog = useConfirmationDialog();

  type specType = "snapshot" | "event";

  // newlyAddedSpec tracks the last spec that was added by this component.
  const [newlyAddedSpec, setNewlyAddedSpec] = useState<specType | undefined>(
    undefined,
  );

  const snapSpecEditor = new FunctionSpecEditor(
    "snapshot",
    functionSpec.funcName,
    functionSpec.snapshotSpec ?? undefined,
    showConfirmationDialog,
    client,
  );
  const eventSpecEditor = new FunctionSpecEditor(
    "event",
    functionSpec.funcName,
    functionSpec.functionStartEvent ?? undefined,
    showConfirmationDialog,
    client,
  );

  const [snapshotsDataOpen, setSnapshotsDataOpen] = useState<boolean>(false);
  const [eventsOpen, setEventsOpen] = useState<boolean>(false);

  const onAddSnapshotSpec = async () => {
    if (await snapSpecEditor.onAddSnapshotSpec()) {
      setNewlyAddedSpec("snapshot");
      setEventsOpen(false);
      setSnapshotsDataOpen(true);
    }
  };

  const onAddFunctionStartEvent = async () => {
    if (await snapSpecEditor.onAddFunctionStartEvent()) {
      setNewlyAddedSpec("event");
      setSnapshotsDataOpen(false);
      setEventsOpen(true);
    }
  };

  const eventTooltip =
    `Events can be emitted every time this function is called.\n` +
    `The events consist of a custom message and can include captured variables.`;
  const snapshotTooltip =
    `Variables/expressions from function can be collected when a snapshot is captured.\n` +
    `Every goroutine stack frame corresponding to this function is represented as a row in the function's frames table.\n` +
    `These rows include captured variables and expression.`;

  const ButtonCapture = memo(() => (
    <Stack flexDirection="row" alignItems="center" gap={1}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          void onAddSnapshotSpec();
        }}
      >
        Capture function data
      </Button>
      <HelpCircle tip={snapshotTooltip} />
    </Stack>
  ));

  const ButtonCreateEvent = memo(() => (
    <Stack flexDirection="row" alignItems="center" gap={1}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          void onAddFunctionStartEvent();
        }}
      >
        Generate event spec
      </Button>
      <HelpCircle tip={eventTooltip} />
    </Stack>
  ));

  const SnapshotDataSummary = memo(() => (
    <Stack direction="row" alignItems="center" gap={1}>
      <Typography>Snapshot spec</Typography>
      <HelpCircle tip={snapshotTooltip} />
      <Tooltip title={"Stop collecting data for this function in snapshots"}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            void snapSpecEditor.onSnapshotSpecDelete().then((deleted) => {
              if (deleted) {
                setSnapshotsDataOpen(false);
              }
            });
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  ));

  const EventsSummary = memo(() => (
    <Stack direction="row" alignItems="center" gap={1}>
      <Typography>Events</Typography>
      <HelpCircle tip={eventTooltip} />
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          void eventSpecEditor.onEventSpecDelete().then((deleted) => {
            if (deleted) {
              setEventsOpen(false);
            }
          });
        }}
      >
        <DeleteIcon />
      </IconButton>
    </Stack>
  ));

  const snapshotSpecLabels = {
    variablesLabel: `Captured variables`,
    variablesTooltip: `The set of expressions that are evaluated and collected in a snapshot when this function is encountered on a goroutine's stack trace.`,
    capturedExprTooltip: `The name of the column representing this captured expression in the function's data table.`,
    tableNameTooltip: `The table name under which this function's frames table is stored in the snapshot database. This is the table name to use in SQL queries.`,
    extraColsTooltip:
      `Extra columns for the function's frames table, in addition to the columns defined implicitly by the captured variables above.\n` +
      `These extra columns are defined using SQL expressions (commonly using JSONPath) evaluated on top of the implicit columns (i.e. the expressions can reference these implicit columns;\n` +
      `the names of implicit columns containing dots should be quoted like "myVar.myField").`,
  };
  const eventsSpecLabels = {
    variablesLabel: `Variables included in the event`,
    variablesTooltip: `The set of variables to collect and expressions to evaluate whenever this function starts executing.`,
    capturedExprTooltip: `The name of the column representing this captured expression in the function's events table.`,
    tableNameTooltip: `The table name under which this function's event table is stored in the events database. This is the table name to use in SQL queries.`,
    extraColsTooltip:
      `Extra columns for the function's events table, in addition to the columns defined implicitly by the captured variables above.\n` +
      `These extra columns are defined using SQL expressions (commonly using JSONPath) evaluated on top of the implicit columns (i.e. the expressions can reference these implicit columns;\n` +
      `the names of implicit columns containing dots should be quoted like "myVar.myField").`,
  };

  if (props.asTableRow) {
    const {rowRef, draggableProps, dragHandle} = props.asTableRow;

    return (
      <React.Fragment key={functionSpec.funcName.QualifiedName}>
        <TableRow
          ref={rowRef}
          {...draggableProps}
          sx={{
            background: (theme) =>
              snapshotsDataOpen || eventsOpen
                ? theme.palette.background.gradient
                : "none",
          }}
        >
          <TableCell>
            <Stack gap={1} pl={3} position="relative">
              <Box position="absolute" top="" left="-10px">
                {dragHandle}
              </Box>

              {Object.entries({
                ["FUNC PKG"]: functionSpec.funcName.Package,
                ["TYPE"]: functionSpec.funcName.Type,
                ["METHOD"]: functionSpec.funcName.Name,
              }).map(([label, value]) => (
                <Box display="grid" gridTemplateColumns="70px 1fr" key={label}>
                  <Typography
                    variant="body4"
                    color="secondary"
                    sx={{whiteSpace: "nowrap"}}
                  >
                    {label}:
                  </Typography>
                  <Typography variant="body4" sx={{wordBreak: "break-word"}}>
                    {value}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </TableCell>

          {functionSpec.snapshotSpec && (
            <ExpandableCell
              state={snapshotsDataOpen}
              onClick={() => {
                setEventsOpen(false);
                setSnapshotsDataOpen(!snapshotsDataOpen);
              }}
            >
              <Box sx={{wordBreak: "break-word"}}>
                capturing {functionSpec.snapshotSpec.collectExprs.length}{" "}
                variables/expression into table
                <br />
                {functionSpec.snapshotSpec.tableName}
              </Box>
            </ExpandableCell>
          )}

          {!functionSpec.snapshotSpec && (
            <TableCell>
              <ButtonCapture />
            </TableCell>
          )}

          {functionSpec.functionStartEvent && (
            <ExpandableCell
              state={eventsOpen}
              onClick={() => {
                setSnapshotsDataOpen(false);
                setEventsOpen(!eventsOpen);
              }}
            >
              <Box sx={{wordBreak: "break-word"}}>
                capturing {functionSpec.functionStartEvent.collectExprs.length}{" "}
                variables/expression into table
                <br />
                {functionSpec.functionStartEvent.tableName}
              </Box>
            </ExpandableCell>
          )}

          {!functionSpec.functionStartEvent && (
            <TableCell>
              <ButtonCreateEvent />
            </TableCell>
          )}
        </TableRow>

        {/* Expandable panel */}
        <TableRow sx={{bgcolor: "secondary.dark"}}>
          <TableCell sx={{py: 0}} colSpan={3}>
            <Collapse in={snapshotsDataOpen} timeout="auto" unmountOnExit>
              <Stack my={2} gap={3}>
                <SnapshotDataSummary />
                <FunctionTableEditor
                  labels={snapshotSpecLabels}
                  binaryID={props.binaryID}
                  pc={props.pc}
                  funcQualifiedName={functionSpec.funcName.QualifiedName}
                  specEditor={snapSpecEditor}
                  tableSpec={functionSpec.snapshotSpec ?? undefined}
                  specType={"snapshot"}
                />
              </Stack>
            </Collapse>
            <Collapse in={eventsOpen} timeout="auto" unmountOnExit>
              <Stack my={2} gap={3}>
                <EventsSummary />
                <EditableLabel
                  text={functionSpec.functionStartEvent?.message ?? ""}
                  label={"The text message to include in these events:"}
                  allowEmpty={true}
                  onSave={eventSpecEditor.onEventMessageEdited}
                  showAsTextField
                />
                <FunctionTableEditor
                  labels={eventsSpecLabels}
                  binaryID={props.binaryID}
                  pc={props.pc}
                  funcQualifiedName={functionSpec.funcName.QualifiedName}
                  tableSpec={functionSpec.functionStartEvent ?? undefined}
                  specEditor={eventSpecEditor}
                  specType={"event"}
                />
              </Stack>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <Card key={functionSpec.funcName.QualifiedName} sx={{overflowY: "auto"}}>
      <CardContent>
        {props.showHeader && (
          <span
            style={{
              width: "100%",
              display: "flex",
              marginBottom: "10px",
            }}
          >
            <span style={{flexGrow: 1}}>
              <DataObjectIcon style={{verticalAlign: "middle"}} />
              <Typography
                variant={"muted"}
                sx={{ml: 1, mr: 1, verticalAlign: "middle"}}
              >
                function
              </Typography>
              <Typography variant={"bold"} sx={{verticalAlign: "middle"}}>
                {functionSpec.funcName.QualifiedName}
              </Typography>
            </span>

            <Tooltip
              title={
                <>
                  <Typography>Delete function spec</Typography>
                  Remove this function from the set of functions for which data
                  is collected in a snapshot when the function is encountered on
                  a goroutine's stack trace.
                </>
              }
            >
              <IconButton
                onClick={() => {
                  void (async () =>
                    deleteFunctionSpec(
                      functionSpec,
                      client,
                      showConfirmationDialog,
                    ))();
                }}
                className={"dense"}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </span>
        )}

        {functionSpec.snapshotSpec ? (
          <Accordion
            defaultExpanded={
              newlyAddedSpec == "snapshot" ||
              props.defaultExpanded == "snapshot" ||
              props.defaultExpanded == "both"
            }
          >
            <AccordionSummary>
              <Stack direction="row" alignItems="center" gap={1}>
                <StackedBarChartIcon sx={{transform: "scaleY(-1)"}} />
                <SnapshotDataSummary />
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{px: 0}}>
              <FunctionTableEditor
                labels={snapshotSpecLabels}
                binaryID={props.binaryID}
                pc={props.pc}
                funcQualifiedName={functionSpec.funcName.QualifiedName}
                tableSpec={functionSpec.snapshotSpec ?? undefined}
                specEditor={snapSpecEditor}
                specType={"snapshot"}
              />
            </AccordionDetails>
          </Accordion>
        ) : (
          <Stack direction="row" alignItems="center" gap={1} px={3}>
            <StackedBarChartIcon sx={{transform: "scaleY(-1)"}} />
            <ButtonCapture />
          </Stack>
        )}

        {functionSpec.functionStartEvent ? (
          <Accordion
            defaultExpanded={
              newlyAddedSpec == "event" ||
              props.defaultExpanded == "snapshot" ||
              props.defaultExpanded == "both"
            }
            sx={{mt: 2}}
          >
            <AccordionSummary>
              <Stack direction="row" alignItems="center" gap={1}>
                <FormatListNumberedIcon />
                <EventsSummary />
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{px: 0}}>
              <EditableLabel
                text={functionSpec.functionStartEvent.message}
                label={"The text message to include in these events:"}
                allowEmpty={true}
                onSave={eventSpecEditor.onEventMessageEdited}
                showAsTextField
              />
              <FunctionTableEditor
                labels={eventsSpecLabels}
                binaryID={props.binaryID}
                pc={props.pc}
                funcQualifiedName={functionSpec.funcName.QualifiedName}
                tableSpec={functionSpec.functionStartEvent ?? undefined}
                specEditor={eventSpecEditor}
                specType={"event"}
              />
            </AccordionDetails>
          </Accordion>
        ) : (
          <Stack direction="row" alignItems="center" gap={1} mt={1} px={3}>
            <FormatListNumberedIcon />
            <ButtonCreateEvent />
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}

import React, {useContext, useEffect, useMemo, useState} from "react";
import {
  FunctionName,
  FunctionSpec,
  FunctionStartEventSpec,
  SnapshotSpec,
} from "src/__generated__/graphql.ts";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Stack,
  Typography,
} from "@mui/material";
import CodeIcon from "@mui/icons-material/Code";
import DataArrayIcon from "@mui/icons-material/DataArray";
import DataObjectIcon from "@mui/icons-material/DataObject";
import {SpecContext} from "src/providers/spec-provider.tsx";
import {HelpCircle} from "@components/HelpCircle.tsx";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {exhaustiveCheck} from "src/util/util.ts";
import {suggestPackagePrefixes} from "@components/filter";
import {SimpleTreeView, TreeItem} from "@mui/x-tree-view";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  functionAutocompletionOption,
  moduleAutocompletionOption,
  packageAutocompletionOption,
  packagePrefixAutocompleteOption,
  toastError,
  typeAutocompletionOption,
} from "src/components/tables/util.tsx";
import {useApolloClient} from "@apollo/client";
import {gql} from "src/__generated__";
import {
  ProcessSelectionJSONSchema,
  processSelectionToGraph,
  useProcessSelection,
} from "@components/ProcessSelector/helpers/processSelection.ts";
import {ProcessSelector} from "@components/ProcessSelector";
import toast from "react-hot-toast";
import {useNavigate, useSearchParams} from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import {FunctionTableEditor} from "src/components/FunctionTableEditor.tsx";
import {FunctionSpecEditor} from "src/util/function-spec-editing.tsx";
import {useConfirmationDialog} from "src/providers/confirmation-dialog.tsx";
import {useBinarySelectionDialog} from "src/providers/binary-selection-dialog.tsx";
import SaveIcon from "@mui/icons-material/Save";
import {UNNAMED_ENV} from "src/constants/unnamed_env";
import {
  computeSearchParams,
  NumberParamUpdater,
  ParamUpdater,
  stateFromURL,
  StringsParamUpdater,
  UpdateSpec,
  ZodParamUpdater,
} from "src/util/url";

type autocompleteOption =
  | functionAutocompletionOption
  | packageAutocompletionOption
  | typeAutocompletionOption
  | moduleAutocompletionOption
  | packagePrefixAutocompleteOption;

type autocompleteOptionWrapper = {
  opt: autocompleteOption;
  // Keep track of the module that this option belongs to. This is needed to
  // match the options against a selected module.
  moduleName: string;
};

const LOG_EVENTS = gql(/* GraphQL */ `
  mutation logEvents($input: LogEventsInput!) {
    logEvents(input: $input) {
      id
    }
  }
`);

function specToAutocompleteSuggestions(
  tree: EventsTree,
): autocompleteOptionWrapper[] {
  const suggestions: autocompleteOptionWrapper[] = [];
  for (const module of tree.modules) {
    suggestions.push({
      opt: new moduleAutocompletionOption(module.moduleName),
      moduleName: module.moduleName,
    });
    for (const pkg of module.packages) {
      suggestions.push({
        opt: new packageAutocompletionOption(pkg.packageName),
        moduleName: module.moduleName,
      });
      for (const type of pkg.types) {
        suggestions.push({
          opt: new typeAutocompletionOption(pkg.packageName, type.typeName),
          moduleName: module.moduleName,
        });
        for (const func of type.events) {
          suggestions.push({
            opt: new functionAutocompletionOption(func.func),
            moduleName: module.moduleName,
          });
        }
      }
      for (const func of pkg.functionEvents) {
        suggestions.push({
          opt: new functionAutocompletionOption(func.func),
          moduleName: module.moduleName,
        });
      }
    }
  }
  return suggestions;
}

// matchAutocompleteOptions takes a list of options and a query string and
// returns the options that match the query. The match field of the returned
// options is set.
function matchAutocompleteOptions(
  options: autocompleteOptionWrapper[],
  query: string,
): autocompleteOptionWrapper[] {
  const matches: autocompleteOptionWrapper[] = [];
  const caseSensitive = query.toLowerCase() != query;

  // Keep track of the package prefix suggestions generated, as multiple
  // packages can generate the same prefix.
  const packagePrefixes = new Set<string>();
  // Usually we don't start with any package prefix suggestions in the input.
  // However, if a package prefix is selected, that one is also part of
  // `options`. So, we initialize packagePrefixes with any such option, so we
  // don't generate it again.
  for (const o of options) {
    if (o.opt.type == "packagePrefix") {
      packagePrefixes.add(o.opt.pkg);
    }
  }

  for (const o of options) {
    if (o.opt.match(query, caseSensitive)) {
      matches.push(o);
    }

    if (o.opt.type == "package") {
      // Generate package prefix suggestions.
      const prefixSuggestion = suggestPackagePrefixes(o.opt.pkgName, query);
      for (const [pkgPrefix, _highlighted] of prefixSuggestion) {
        if (packagePrefixes.has(pkgPrefix)) {
          continue;
        }
        const p = new packagePrefixAutocompleteOption(pkgPrefix);
        if (p.match(query, caseSensitive)) {
          matches.push({opt: p, moduleName: o.moduleName});
          packagePrefixes.add(pkgPrefix);
        } else {
          throw new Error(
            `suggestion was expected to match: ${pkgPrefix} query ${query}`,
          );
        }
      }
    }
  }

  return matches;
}

function getOptionKey(option: autocompleteOptionWrapper | string): string {
  if (typeof option === "string") {
    return option;
  }
  return option.opt.key();
}

function getOptionLabel(option: autocompleteOptionWrapper | string): string {
  if (typeof option === "string") {
    return option;
  }
  return option.opt.label();
}

export default function EnableEvents(): React.JSX.Element {
  const client = useApolloClient();
  const navigate = useNavigate();
  const spec = useContext(SpecContext);
  const tree: EventsTree = useMemo(() => specToTree(spec), [spec]);
  const autocompleteOptions = useMemo(
    () => specToAutocompleteSuggestions(tree),
    [tree],
  );

  const [filterInputValue, setFilterInputValue] = useState<string>("");
  const [filterValue, setFilterValue] = useState<
    autocompleteOptionWrapper | string | null
  >(null);
  const [expandedNodeIDs, setExpandedNodeIDs] = useState<string[]>([]);
  const [durationError, setDurationError] = useState<boolean>(false);
  const [traceInProgress, setTraceInProgress] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const paramUpdaters = makeParamUpdaters();
  const {
    processSelection: processSelectionFromURL,
    selectedEvents,
    logDuration,
  } = stateFromURL(searchParams, paramUpdaters);
  function updateSearchParams(update: UpdateSpec<typeof paramUpdaters>): void {
    setSearchParams(computeSearchParams(searchParams, update, paramUpdaters));
  }
  const [processSelection, agentReport] = useProcessSelection(
    processSelectionFromURL,
  );

  // Synchronize the resulting processSelection with the URL. We need to do this
  // in an effect; we cannot call updateSearchParams directly from the render.
  useEffect(() => {
    // If the agent report is not available yet, don't update the URL.
    if (agentReport == undefined) {
      return;
    }
    if (
      (processSelection == undefined) !=
        (processSelectionFromURL == undefined) ||
      (processSelection != undefined &&
        processSelectionFromURL != undefined &&
        JSON.stringify(processSelection.toRaw()) !=
          JSON.stringify(processSelectionFromURL))
    ) {
      updateSearchParams({processSelection: processSelection?.toRaw()});
    }
  });

  const durationSeconds = logDuration ?? 10;

  // Filter the events according to the filter values. Note that expandedNodeIDs
  // has already been adjusted on filter changes.
  let filteredTree: EventsTree;
  if (filterValue == null) {
    filteredTree = tree;
  } else {
    const filterRes = tree.filter(filterValue);
    filteredTree = filterRes.filtered;
  }

  async function onEnableEvents(): Promise<void> {
    if (processSelection == undefined) {
      throw new Error("no processes selected");
    }

    const [env, selection] = processSelectionToGraph(processSelection.toRaw());
    setTraceInProgress(true);

    try {
      const {data, errors} = await client.mutate({
        mutation: LOG_EVENTS,
        variables: {
          input: {
            durationMillis: durationSeconds * 1000,
            environment: env == UNNAMED_ENV ? null : env,
            selections: selection,
            probeModulePackagePaths: selectedEvents
              .filter((group) => group.type == "module")
              .map((m) => m.pkgPath),
            probePackages: selectedEvents
              .filter((group) => group.type == "pkg")
              .map((p) => p.packageName),
            probeTypes: selectedEvents
              .filter((group) => group.type == "type")
              .map((t) => t.typeName),
            probeFuncs: selectedEvents
              .filter((group) => group.type == "func")
              .map((f) => f.funcQualifiedName),
          },
        },
      });
      if (errors) {
        // This was not supposed to happen; the error was supposed to be thrown.
        // See:
        // https://community.apollographql.com/t/usesuspensequery-and-query-errors-confusion/6957/5?u=andreimatei
        throw errors;
      }

      const logID = data!.logEvents.id;
      toast("Events collection in progress.");
      navigate(`/live-log/${logID}`);
    } catch (e) {
      toastError(e);
    }
    setTraceInProgress(false);
  }

  return (
    <>
      <Box my={3}>
        <Typography variant="h1">Enable events generation</Typography>
        <Typography variant="body3" color="primary.light">
          Enable selected events for a specified duration. Once enabled, the
          generated events will be streamed, as well as saved for later
          analysis.
        </Typography>
      </Box>

      <Stack gap={3}>
        <Card>
          <CardHeader title="Select processes to enable events for" />
          <CardContent>
            <ProcessSelector
              selection={processSelection}
              agentReports={agentReport}
              onSelectionUpdated={(newSelection) => {
                updateSearchParams({processSelection: newSelection});
              }}
            />
            {(processSelection == undefined || processSelection.empty()) && (
              <Typography variant={"error"}>Selection required</Typography>
            )}
          </CardContent>
        </Card>

        <Card>
          <CardHeader
            title="Select events to enable"
            subheader="Only the selected events will be generated."
          />
          <CardContent>
            <Stack direction="row" alignItems="center" gap={1}>
              <Autocomplete
                fullWidth
                options={autocompleteOptions}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Filter events" />
                )}
                // We take control over the filtering process so that we can do our
                // own matching of the query to the suggestions. Also, the suggestions
                // change in response to the query -- parts of the string get
                // highlighted. Also, some suggestions (the package prefixes) are
                // generated based on the query.
                filterOptions={(options, state) =>
                  matchAutocompleteOptions(options, state.inputValue)
                }
                renderOption={(props, option: autocompleteOptionWrapper) =>
                  option.opt.render(props)
                }
                getOptionKey={getOptionKey}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                onChange={(_, value) => {
                  setFilterValue(value);
                  if (value == null) {
                    setExpandedNodeIDs([]);
                  } else {
                    const filterRes = tree.filter(value);

                    // Also expand the ancestors of any node in expandedNodeIDs.
                    const nodes: TreeNode[] = tree.modules.map(
                      TreeNode.fromModule,
                    );
                    const expandedNodeIDs = new Set<string>(
                      filterRes.matchingNodesIDs,
                    );
                    for (const n of nodes) {
                      n.addAncestors(expandedNodeIDs);
                    }
                    setExpandedNodeIDs(Array.from(expandedNodeIDs));
                  }
                }}
                value={filterValue}
                inputValue={filterInputValue}
                onInputChange={(_event, value) => setFilterInputValue(value)}
                freeSolo={true}
              />
              <HelpCircle
                tip={`Filter the events by function name, type, package or module.`}
              />
            </Stack>
            <Box sx={{mt: 2}}>
              <EventsTreeView
                tree={filteredTree}
                expandedNodeIDs={expandedNodeIDs}
                setExpandedNodeIDs={setExpandedNodeIDs}
                checkedNodes={selectedEvents}
                setCheckedNodes={(
                  eventGroupSelections: EventsGroupSelection[],
                ) => {
                  updateSearchParams({selectedEvents: eventGroupSelections});
                }}
              />
            </Box>
            {selectedEvents.length == 0 && (
              <Typography variant={"error"}>Selection required</Typography>
            )}
          </CardContent>
        </Card>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Typography>Enable events generation for</Typography>
          <TextField
            sx={{width: "4em"}}
            color="secondary"
            value={durationSeconds}
            error={durationError}
            helperText={durationError ? "Invalid duration" : ""}
            onChange={(e) => {
              const valStr = e.target.value;
              const val = parseInt(valStr);
              if (isNaN(val)) {
                setDurationError(true);
              } else {
                setDurationError(false);
                updateSearchParams({logDuration: val});
              }
            }}
          />
          <Typography>seconds.</Typography>
        </Stack>
        <LoadingButton
          sx={{width: "fit-content"}}
          loadingPosition={"start"}
          startIcon={<SaveIcon />}
          loading={traceInProgress}
          variant={"contained"}
          onClick={() => void onEnableEvents()}
          onAuxClick={() => void onEnableEvents()}
          disabled={
            processSelection == undefined ||
            processSelection.empty() ||
            selectedEvents.length == 0 ||
            durationError
          }
        >
          Enable events log
        </LoadingButton>
      </Stack>
    </>
  );
}

function isOptionEqualToValue(
  opt: autocompleteOptionWrapper,
  value: autocompleteOptionWrapper | string,
): boolean {
  if (typeof value == "string") {
    return false;
  }

  return opt.opt.equals(value.opt);
}

type nodeStatus = "all" | "some" | "none";

function computeBottomUpStatus(
  n: TreeNode,
  parentChecked: boolean,
  checkedNodeIDs: string[],
  m: Map<string, nodeStatus>,
) {
  const nodeCheckedExplicitly = checkedNodeIDs.includes(n.id);
  for (const c of n.children) {
    computeBottomUpStatus(
      c,
      parentChecked || nodeCheckedExplicitly,
      checkedNodeIDs,
      m,
    );
  }
  if (parentChecked || nodeCheckedExplicitly) {
    m.set(n.id, "all");
    return;
  }
  if (n.children.length == 0) {
    m.set(n.id, "none");
    return;
  }
  if (n.children.every((c) => m.get(c.id) == "all")) {
    m.set(n.id, "all");
  } else if (
    n.children.some((c) => {
      const childStatus = m.get(c.id);
      return childStatus == "some" || childStatus == "all";
    })
  ) {
    m.set(n.id, "some");
  } else {
    m.set(n.id, "none");
  }
}

function linkParents(node: TreeNode, parent: TreeNode | undefined) {
  node.parent = parent;
  for (const c of node.children) {
    linkParents(c, node);
  }
}

// EventsTreeView renders a tree with all functions that have events specs,
// together with the modules, packages and types parents.
function EventsTreeView(props: {
  tree: EventsTree;
  // The IDs of nodes to expand. These IDs need to correspond to what nodeID()
  // returns for nodes in `tree`.
  expandedNodeIDs: string[];
  // Callback used when the list of expanded nodes changes.
  setExpandedNodeIDs: (expandedNodeIDs: string[]) => void;
  checkedNodes: EventsGroupSelection[];
  setCheckedNodes: (checkedNodes: EventsGroupSelection[]) => void;
}): React.JSX.Element {
  const nodes: TreeNode[] = props.tree.modules.map(TreeNode.fromModule);
  for (const n of nodes) {
    linkParents(n, undefined /* parent */);
  }
  const nodeStatuses = new Map<string, nodeStatus>();
  for (const n of nodes) {
    computeBottomUpStatus(
      n,
      false /* parentChecked */,
      props.checkedNodes.map((n) => n.nodeID()),
      nodeStatuses,
    );
  }

  function toggleNode(node: TreeNode, checked: boolean) {
    let newCheckedNodes: EventsGroupSelection[];
    if (checked) {
      newCheckedNodes = [...props.checkedNodes];
      newCheckedNodes.push(node.events.toSelection());
    } else {
      // First, uncheck the node and all its children, recursively.
      const childIDs = node.treeIDs();
      newCheckedNodes = props.checkedNodes.filter((n) => {
        return !childIDs.includes(n.nodeID());
      });

      const fixup = () => {
        if (node.parent == undefined) {
          return;
        }
        let parent: TreeNode = node.parent;
        while (parent != undefined) {
          if (props.checkedNodes.some((n) => n.nodeID() == parent.id)) {
            // I've found a parent that was checked. Uncheck it, and check all
            // its children except the child through which we got here.
            newCheckedNodes = props.checkedNodes.filter(
              (n) => n.nodeID() != parent.id,
            );

            for (const c of parent.children) {
              if (c.id == node.id) {
                // Ignore our node.
                continue;
              }
              const childChecked = props.checkedNodes.some(
                (n) => n.nodeID() == c.id,
              );
              if (!childChecked) {
                newCheckedNodes.push(c.events.toSelection());
              }
            }
          }
          if (parent.parent == undefined) {
            return;
          }
          parent = parent.parent;
          node = node.parent!;
        }
      };

      // Now walk to the root and, whenever we find a node that's checked, we
      // replace it with all its children except the child through which we
      // got there.
      fixup();
    }

    props.setCheckedNodes(newCheckedNodes);
  }

  return (
    <SimpleTreeView
      multiSelect
      disableSelection={true}
      slots={{expandIcon: ChevronRightIcon, collapseIcon: ExpandMoreIcon}}
      expandedItems={props.expandedNodeIDs}
      onExpandedItemsChange={(_event, expandedNodeIDs: string[]) => {
        props.setExpandedNodeIDs(expandedNodeIDs);
      }}
    >
      {nodes.length == 0 ? (
        <Box key={"no-fields"}>
          <Typography variant={"explanation"}>No events defined.</Typography>
        </Box>
      ) : (
        <>
          {nodes.map((n) => (
            <EventsTreeNode
              key={n.id}
              node={n}
              nodeStatuses={nodeStatuses}
              expandedNodeIDs={props.expandedNodeIDs}
              setExpandedNodeIDs={props.setExpandedNodeIDs}
              toggleNode={toggleNode}
            />
          ))}
        </>
      )}
    </SimpleTreeView>
  );
}

type EventsGroupSelection =
  | ModuleEventsSelection
  | PackageEventsSelection
  | TypeEventsSelection
  | EventInfoSelection;

class ModuleEventsSelection {
  type = "module" as const;
  // The prefix of the package path that defines this module.
  pkgPath: string;

  constructor(pkgPath: string) {
    this.pkgPath = pkgPath;
  }

  nodeID = (): string => {
    return `mod:${this.pkgPath}`;
  };
}

class PackageEventsSelection {
  type = "pkg" as const;
  packageName: string;

  constructor(packageName: string) {
    this.packageName = packageName;
  }

  nodeID = (): string => {
    return `pkg:${this.packageName}`;
  };
}

class TypeEventsSelection {
  type = "type" as const;
  typeName: string;
  pkgName: string;

  constructor(typeName: string, pkgName: string) {
    this.typeName = typeName;
    this.pkgName = pkgName;
  }

  nodeID = (): string => {
    return `pkg:${this.pkgName}:type:${this.typeName}`;
  };
}

class EventInfoSelection {
  type = "func" as const;
  funcQualifiedName: string;

  constructor(funcQualifiedName: string) {
    this.funcQualifiedName = funcQualifiedName;
  }

  nodeID = (): string => {
    return `func:${this.funcQualifiedName}`;
  };
}

// EventsGroups represents a group of event specs (or a single event) that can
// be selected for inclusion in a log.
type EventsGroup = ModuleEvents | PackageEvents | TypeEvents | EventInfo;

class TreeNode {
  type: "module" | "package" | "type" | "function";
  events: EventsGroup;
  name: string;
  children: TreeNode[];
  parent: TreeNode | undefined;
  // A unique ID for this node, as the TreeItem component wants.
  id: string;

  constructor(
    type: "module" | "package" | "type" | "function",
    events: EventsGroup,
    name: string,
    children: TreeNode[],
    id: string,
  ) {
    this.type = type;
    this.events = events;
    this.name = name;
    this.children = children;
    this.id = id;
  }

  static fromModule = (module: ModuleEvents): TreeNode => {
    return new TreeNode(
      "module",
      module,
      module.moduleName,
      module.packages.map(TreeNode.fromPackage),
      module.nodeID(),
    );
  };

  static fromPackage = (pkg: PackageEvents): TreeNode => {
    return new TreeNode(
      "package",
      pkg,
      pkg.packageName,
      [
        ...pkg.types.map(TreeNode.fromType),
        ...pkg.functionEvents.map(TreeNode.fromEvent),
      ],
      pkg.nodeID(),
    );
  };

  static fromType = (type: TypeEvents): TreeNode => {
    return new TreeNode(
      "type",
      type,
      type.typeName,
      type.events.map(TreeNode.fromEvent),
      type.nodeID(),
    );
  };

  static fromEvent = (func: EventInfo): TreeNode => {
    return new TreeNode("function", func, func.func.Name, [], func.nodeID());
  };

  // addAncestors takes a set of nodeIDs and adds all ancestors of the
  // respective nodes to the set. Returns true if the set was modified (if the
  // set was modified, that implies that this module was also added to the set).
  addAncestors = (nodeIDs: Set<string>): boolean => {
    let anyAdded = false;
    for (const n of this.children) {
      const added = n.addAncestors(nodeIDs);
      anyAdded = anyAdded || added;
    }
    let modified = false;
    if (anyAdded || nodeIDs.has(this.id)) {
      modified = true;
      nodeIDs.add(this.id);
    }
    return modified;
  };

  treeIDs(): string[] {
    const ids: string[] = [this.id];
    for (const c of this.children) {
      ids.push(c.id);
      ids.push(...c.treeIDs());
    }
    return ids;
  }
}

type filterResults = {
  filtered: EventsTree;
  matchingNodesIDs: string[];
};

class EventsTree {
  modules: ModuleEvents[];

  constructor(modules: ModuleEvents[] = []) {
    this.modules = modules;
  }

  addFunctionStartEvent = (
    event: FunctionStartEventSpec,
    moduleName: string,
    modulePkgPath: string,
    functionSpec: FunctionSpec,
  ) => {
    let module = this.modules.find((m) => m.moduleName == moduleName);
    if (!module) {
      const newModule = new ModuleEvents(moduleName, modulePkgPath, []);
      this.modules.push(newModule);
      module = newModule;
    }
    let pkg = module.packages.find(
      (p) => p.packageName == functionSpec.funcName.Package,
    );
    if (!pkg) {
      const newPkg = new PackageEvents(functionSpec.funcName.Package, [], []);
      module.packages.push(newPkg);
      pkg = newPkg;
    }

    if (functionSpec.funcName.Type) {
      let type = pkg.types.find(
        (t) => t.typeName == functionSpec.funcName.Type,
      );
      if (!type) {
        const newType = new TypeEvents(
          functionSpec.funcName.Type,
          pkg.packageName,
          [],
        );
        pkg.types.push(newType);
        type = newType;
      }
      type.events.push(new EventInfo(event, functionSpec.funcName));
    } else {
      pkg.functionEvents.push(new EventInfo(event, functionSpec.funcName));
    }
  };

  // filter takes a selected autocomplete option or a string, and returns the
  // filtered tree containing the nodes that match the query and their children.
  // If the query is a string, all nodes that contain that string are matches.
  filter = (opt: autocompleteOptionWrapper | string): filterResults => {
    if (typeof opt == "string") {
      const newModules: ModuleEvents[] = [];
      const matchingNodeIDs: string[] = [];
      for (const e of this.modules) {
        const res = e.filter(opt);
        if (res) {
          newModules.push(res.filtered);
          matchingNodeIDs.push(...res.matchingNodeIDs);
        }
      }

      return {
        filtered: new EventsTree(newModules),
        matchingNodesIDs: matchingNodeIDs,
      };
    }

    const module = this.modules.find((m) => m.moduleName == opt.moduleName);
    if (module == undefined) {
      throw new Error(`Module ${opt.moduleName} not found`);
    }

    const matchingNodeIDs = [module.nodeID()];

    const inner = opt.opt;

    if (inner.type == "module") {
      return {
        filtered: new EventsTree([module]),
        matchingNodesIDs: matchingNodeIDs,
      };
    }

    if (inner.type == "packagePrefix") {
      const newModules: ModuleEvents[] = [];
      for (const m of this.modules) {
        const newPackages: PackageEvents[] = [];
        for (const p of m.packages) {
          if (p.packageName.startsWith(inner.pkg)) {
            newPackages.push(p);
            matchingNodeIDs.push(p.nodeID());
          }
        }

        if (newPackages.length > 0) {
          newModules.push(
            new ModuleEvents(m.moduleName, m.pkgPath, newPackages),
          );
          matchingNodeIDs.push(m.nodeID());
        }
      }
      return {
        filtered: new EventsTree(newModules),
        matchingNodesIDs: matchingNodeIDs,
      };
    }

    const pkgName =
      inner.type != "function" ? inner.pkgName : inner.function.Package;
    const pkg = module.packages.find((p) => p.packageName == pkgName);
    if (pkg == undefined) {
      throw new Error(`Package ${pkgName} not found`);
    }
    matchingNodeIDs.push(pkg.nodeID());

    if (inner.type == "package") {
      return {
        filtered: new EventsTree([
          new ModuleEvents(module.moduleName, module.pkgPath, [pkg]),
        ]),
        matchingNodesIDs: matchingNodeIDs,
      };
    }

    if (inner.type == "type") {
      const type = pkg.types.find((t) => t.typeName == inner.typeName);
      if (type == undefined) {
        throw new Error(
          `Type ${inner.typeName} not found in package ${pkgName}`,
        );
      }
      matchingNodeIDs.push(type.nodeID());

      return {
        filtered: new EventsTree([
          new ModuleEvents(module.moduleName, module.pkgPath, [
            new PackageEvents(pkgName, [type], []),
          ]),
        ]),
        matchingNodesIDs: matchingNodeIDs,
      };
    }

    if (inner.type == "function") {
      const typeName = inner.function.Type;
      if (typeName) {
        const type = pkg.types.find((t) => t.typeName == typeName);
        if (type == undefined) {
          throw new Error(`Type ${typeName} not found in package ${pkgName}`);
        }
        matchingNodeIDs.push(type.nodeID());

        const func = type.events.find(
          (e) => e.func.Name == inner.function.Name,
        );
        if (func == undefined) {
          throw new Error(
            `Function ${inner.function.Name} not found in type ${typeName}`,
          );
        }
        matchingNodeIDs.push(func.nodeID());

        return {
          filtered: new EventsTree([
            new ModuleEvents(module.moduleName, module.pkgPath, [
              new PackageEvents(
                pkgName,
                [new TypeEvents(typeName, pkgName, [func])],
                [],
              ),
            ]),
          ]),
          matchingNodesIDs: matchingNodeIDs,
        };
      } else {
        // The function is not a method.
        const func = pkg.functionEvents.find(
          (e) => e.func.Name == inner.function.Name,
        );
        if (func == undefined) {
          throw new Error(
            `Function ${inner.function.Name} not found in package ${pkgName}`,
          );
        }
        matchingNodeIDs.push(func.nodeID());
        return {
          filtered: new EventsTree([
            new ModuleEvents(module.moduleName, module.pkgPath, [
              new PackageEvents(pkgName, [], [func]),
            ]),
          ]),
          matchingNodesIDs: matchingNodeIDs,
        };
      }
    }
    exhaustiveCheck(inner);
  };
}

class ModuleEvents {
  type = "module" as const;
  moduleName: string;
  // The prefix of the package path that defines this module.
  pkgPath: string;
  packages: PackageEvents[];

  constructor(moduleName: string, pkgPath: string, packages: PackageEvents[]) {
    this.moduleName = moduleName;
    this.pkgPath = pkgPath;
    this.packages = packages;
  }

  filter = (
    query: string,
  ): {filtered: ModuleEvents; matchingNodeIDs: string[]} | undefined => {
    if (this.moduleName.includes(query)) {
      // TODO(andrei): Also test the query against the children, recursively, to
      // collect more matchingNodeIDs?
      return {filtered: this, matchingNodeIDs: [this.nodeID()]};
    }

    const newPkgs: PackageEvents[] = [];
    const matchingNodeIDs: string[] = [this.nodeID()];
    for (const e of this.packages) {
      const res = e.filter(query);
      if (res) {
        newPkgs.push(res.filtered);
        matchingNodeIDs.push(...res.matchingNodeIDs);
      }
    }

    if (newPkgs.length > 0) {
      return {
        filtered: new ModuleEvents(this.moduleName, this.pkgPath, newPkgs),
        matchingNodeIDs,
      };
    }
    return undefined;
  };

  nodeID = (): string => {
    return this.toSelection().nodeID();
  };

  toSelection = (): ModuleEventsSelection => {
    return new ModuleEventsSelection(this.pkgPath);
  };
}

class PackageEvents {
  type = "pkg" as const;
  packageName: string = "";
  types: TypeEvents[] = [];
  // Events corresponding to functions in this package (as opposed to methods on
  // types in this package, which are stored in `types`).
  functionEvents: EventInfo[] = [];

  constructor(
    packageName: string,
    types: TypeEvents[],
    functionEvents: EventInfo[],
  ) {
    this.packageName = packageName;
    this.types = types;
    this.functionEvents = functionEvents;
  }

  filter = (
    query: string,
  ): {filtered: PackageEvents; matchingNodeIDs: string[]} | undefined => {
    if (this.packageName.includes(query)) {
      return {filtered: this, matchingNodeIDs: [this.nodeID()]};
    }

    const matchingNodeIDs: string[] = [this.nodeID()];
    const newFunctionEvents: EventInfo[] = [];
    for (const e of this.functionEvents) {
      const res = e.filter(query);
      if (res) {
        newFunctionEvents.push(res.filtered);
        matchingNodeIDs.push(...res.matchingNodeIDs);
      }
    }
    const newTypes: TypeEvents[] = [];
    for (const t of this.types) {
      const res = t.filter(query);
      if (res) {
        newTypes.push(res.filtered);
        matchingNodeIDs.push(...res.matchingNodeIDs);
      }
    }
    if (newTypes.length > 0 || newFunctionEvents.length > 0) {
      return {
        filtered: new PackageEvents(
          this.packageName,
          newTypes,
          newFunctionEvents,
        ),
        matchingNodeIDs: matchingNodeIDs,
      };
    }
    return undefined;
  };

  nodeID = (): string => {
    return this.toSelection().nodeID();
  };

  toSelection = (): PackageEventsSelection => {
    return new PackageEventsSelection(this.packageName);
  };
}

class TypeEvents {
  type = "type" as const;
  typeName: string = "";
  pkgName: string = "";
  events: EventInfo[] = [];

  constructor(typeName: string, pkgName: string, events: EventInfo[]) {
    this.typeName = typeName;
    this.pkgName = pkgName;
    this.events = events;
  }

  filter = (
    query: string,
  ): {filtered: TypeEvents; matchingNodeIDs: string[]} | undefined => {
    if (this.typeName.includes(query)) {
      return {filtered: this, matchingNodeIDs: [this.nodeID()]};
    }

    const newEvents: EventInfo[] = [];
    const matchingNodeIDs: string[] = [this.nodeID()];
    for (const e of this.events) {
      const res = e.filter(query);
      if (res) {
        newEvents.push(res.filtered);
        matchingNodeIDs.push(...res.matchingNodeIDs);
      }
    }
    if (newEvents.length > 0) {
      return {
        filtered: new TypeEvents(this.typeName, this.pkgName, newEvents),
        matchingNodeIDs: matchingNodeIDs,
      };
    }
    return undefined;
  };

  nodeID = (): string => {
    return this.toSelection().nodeID();
  };

  toSelection = (): TypeEventsSelection => {
    return new TypeEventsSelection(this.typeName, this.pkgName);
  };
}

class EventInfo {
  type = "func" as const;
  spec: FunctionStartEventSpec;
  func: FunctionName;

  constructor(spec: FunctionStartEventSpec, func: FunctionName) {
    this.spec = spec;
    this.func = func;
  }

  filter = (
    query: string,
  ): {filtered: EventInfo; matchingNodeIDs: string[]} | undefined => {
    if (this.func.Name.includes(query)) {
      return {filtered: this, matchingNodeIDs: [this.nodeID()]};
    }
    return undefined;
  };

  nodeID = (): string => {
    return this.toSelection().nodeID();
  };

  toSelection = (): EventInfoSelection => {
    return new EventInfoSelection(this.func.QualifiedName);
  };
}

function specToTree(spec: SnapshotSpec): EventsTree {
  const eventsTree = new EventsTree();
  for (const module of spec.modules) {
    for (const func of module.functionSpecs) {
      if (func.functionStartEvent) {
        eventsTree.addFunctionStartEvent(
          func.functionStartEvent,
          module.name,
          module.pkgPath,
          func,
        );
      }
    }
  }
  return eventsTree;
}

function EventsTreeNode(props: {
  node: TreeNode;
  nodeStatuses: Map<string, nodeStatus>;
  // The IDs of nodes to expand. These IDs need to correspond to what nodeID()
  // returns for nodes in `tree`.
  expandedNodeIDs: string[];
  // Callback used when the list of expanded nodes changes.
  setExpandedNodeIDs: (expandedNodeIDs: string[]) => void;
  toggleNode: (node: TreeNode, checked: boolean) => void;
}): React.JSX.Element {
  const client = useApolloClient();
  const showConfirmationDialog = useConfirmationDialog();
  const showBinarySelectionDialog = useBinarySelectionDialog();
  const [showVars, setShowVars] = useState(false);
  const [binaryID, setBinaryID] = useState<string | undefined>(undefined);

  const onNodeToggle = (checked: boolean, node: TreeNode): void => {
    props.toggleNode(node, checked);
  };

  async function promptForBinarySelection() {
    const binaryID = await showBinarySelectionDialog(
      undefined /* snapshotID */,
      undefined /* funcQualifiedName */,
    );
    if (binaryID == undefined) {
      return;
    }
    setBinaryID(binaryID);
  }

  const node = props.node;
  const status = props.nodeStatuses.get(props.node.id);
  if (status == undefined) {
    throw new Error(`bottom-up status not found for node ${props.node.id}`);
  }
  const ev = node.events;
  const eventSpec = ev.type == "func" ? ev.spec : undefined;

  const eventSpecEditor =
    ev.type == "func"
      ? new FunctionSpecEditor(
          "event",
          ev.func,
          eventSpec,
          showConfirmationDialog,
          client,
        )
      : undefined;

  return (
    <TreeItem
      key={node.name}
      itemId={node.id}
      label={
        <span>
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <Checkbox
              checked={status == "all"}
              indeterminate={status == "some"}
              onChange={(event) => onNodeToggle(event.target.checked, node)}
              onClick={(e) => e.stopPropagation()}
            />
            {node.type == "module" && <CodeIcon />}
            {node.type == "type" && <DataArrayIcon />}
            {/*TODO: don't use the same icon for function and package */}
            {node.type == "package" && <DataObjectIcon />}
            {node.type == "function" && <DataObjectIcon />}
            <Typography>{node.name}</Typography>
            {ev.type == "func" &&
              (showVars ? (
                <Button onClick={() => setShowVars(false)}>
                  Hide captured variables
                </Button>
              ) : (
                <Button onClick={() => setShowVars(true)}>
                  Show captured variables
                </Button>
              ))}
          </Stack>
          {ev.type == "func" && showVars && (
            <FunctionTableEditor
              labels={{
                variablesLabel: "Variables included in the event",
                variablesTooltip: `The set of variables to collect and expressions to evaluate
                    whenever this function starts executing.`,
                capturedExprTooltip: `The name of the column representing this captured
                    expression in the function's events table.`,
                tableNameTooltip: `The table name under which this function's event table is stored in
                    the events database. This is the table name to use in SQL queries.`,
                extraColsTooltip: `Extra columns for the function's events table, in addition to 
                    the columns defined implicitly by the captured variables above. These 
                    extra columns are defined using SQL expressions (commonly using JSONPath) evaluated on top 
                    of the implicit columns (i.e. the expressions can reference these implicit columns; 
                    the names of implicit columns containing dots should be quoted like "myVar.myField").`,
              }}
              binaryID={binaryID ?? (() => void promptForBinarySelection())}
              specEditor={eventSpecEditor!}
              tableSpec={eventSpec}
              funcQualifiedName={ev.func.QualifiedName}
              specType={"event"}
            />
          )}
        </span>
      }
    >
      {node.children.map((n: TreeNode) => (
        <EventsTreeNode
          key={n.id}
          node={n}
          nodeStatuses={props.nodeStatuses}
          toggleNode={props.toggleNode}
          expandedNodeIDs={props.expandedNodeIDs}
          setExpandedNodeIDs={props.setExpandedNodeIDs}
        />
      ))}
    </TreeItem>
  );
}

type paramUpdaters = {
  processSelection: ZodParamUpdater<typeof ProcessSelectionJSONSchema>;
  logDuration: NumberParamUpdater;
  selectedEvents: ParamUpdater<EventsGroupSelection[], EventsGroupSelection[]>;
};

function makeParamUpdaters(): paramUpdaters {
  const sp = new StringsParamUpdater("events");
  return {
    processSelection: new ZodParamUpdater(
      "processes",
      ProcessSelectionJSONSchema,
    ),
    logDuration: new NumberParamUpdater("logDuration"),
    selectedEvents: new ParamUpdater<
      EventsGroupSelection[],
      EventsGroupSelection[]
    >(
      "events",
      (
        _param: string,
        searchParams: URLSearchParams,
      ): EventsGroupSelection[] => {
        const events: string[] = sp.get(searchParams);
        return events.map((s) => {
          if (s.startsWith("mod:")) {
            return new ModuleEventsSelection(s.slice(4));
          }
          if (s.startsWith("pkg:")) {
            return new PackageEventsSelection(s.slice(4));
          }
          if (s.startsWith("pkg:") && s.includes(":type:")) {
            const parts = s.split(":");
            return new TypeEventsSelection(parts[3], parts[1]);
          }
          if (s.startsWith("func:")) {
            return new EventInfoSelection(s.slice(5));
          }
          throw new Error(`unexpected events selection: ${s}`);
        });
      },
      (
        _param: string,
        searchParams: URLSearchParams,
        newValue: EventsGroupSelection[],
      ) => {
        return sp.update(
          searchParams,
          newValue.map((e: EventsGroupSelection): string => e.nodeID()),
        );
      },
    ),
  };
}

import React, {useEffect} from "react";
import Autocomplete from "@mui/material/Autocomplete";
import {GET_ENVIRONMENTS} from "../pages/Snaphots/gqlHelper.ts";
import {useSuspenseQuery} from "@apollo/client";
import TextField from "@mui/material/TextField";
import {noEnvSymbol} from "src/pages/Agents/components/AllProcesses/AllProcesses.tsx";

type Props<AllowEmpty extends boolean> = {
  allowEmpty: AllowEmpty;
  // selectedEnv is the selected environment. It must match one of the
  // environments returned by GET_ENVIRONMENTS. undefined means that no
  // environment is selected.
  selectedEnv: string | typeof noEnvSymbol | undefined;
  onChange: (newEnv: string | typeof noEnvSymbol | undefined) => void;
};

export default function SelectorEnvironment<AllowEmpty extends boolean>(
  props: Props<AllowEmpty>,
) {
  const {data: environmentsRes} = useSuspenseQuery(GET_ENVIRONMENTS);
  const environments = environmentsRes.getEnvironments;

  const setEnvironment = (env: string | typeof noEnvSymbol | null) => {
    if (env == null) {
      props.onChange(undefined);
    } else if (env == noEnvSymbol) {
      props.onChange(undefined);
    } else {
      props.onChange(env);
    }
  };

  const parseEnvString = (env: string): string | typeof noEnvSymbol => {
    if (env == "") {
      return noEnvSymbol;
    }
    return env;
  };

  let selectedEnv: string | typeof noEnvSymbol | null =
    props.selectedEnv ?? null;
  if (selectedEnv == null && !props.allowEmpty) {
    if (environments.length > 0) {
      selectedEnv = parseEnvString(environmentsRes.getEnvironments[0]);
    }
  }

  // If we were not configured with a selected environment but an empty value is
  // disallowed, then pretend that the user selected the first env.
  useEffect(() => {
    if (selectedEnv != props.selectedEnv) {
      props.onChange(selectedEnv ?? undefined);
    }
  }, []);

  return (
    <Autocomplete
      disablePortal
      value={selectedEnv}
      options={environmentsRes.getEnvironments.map((env: string) =>
        env != "" ? env : noEnvSymbol,
      )}
      sx={{width: 300}}
      autoHighlight={true}
      renderInput={(params) => (
        <TextField {...params} placeholder="Environment" color="secondary" />
      )}
      onChange={(_event, newValue) => {
        setEnvironment(newValue);
      }}
      disableClearable={!props.allowEmpty}
      getOptionLabel={(opt) =>
        opt == noEnvSymbol ? "<environment not set>" : opt
      }
    />
  );
}

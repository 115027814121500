import {
  ColumnInfo,
  GoroutineId,
  LinkSpec,
  Row,
} from "src/__generated__/graphql.ts";
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {useState} from "react";
import MyTableCell from "src/components/cell.tsx";
import {gql} from "src/__generated__/gql.ts";
import {useQuery} from "@apollo/client";
import {useSnapshotState} from "src/providers/snapshot-state.tsx";
import {Link} from "react-router-dom";
import {parseGoroutineID, ProcessInfo} from "src/util/util.ts";
import {linksByColumn, resolvedLinksInfo} from "src/util/links.ts";
import {schemaInfo, tableSchemaExt} from "src/util/spec.ts";
import {useProcessResolver} from "@providers/processResolverProvider.tsx";

const GET_LINK_SPECS = gql(/* GraphQL */ `
  query GetLinkSpec($linkIDs: [String!]!) {
    getLinkSpecs(ids: $linkIDs) {
      ...FullLinkSpec
    }
  }
`);

type FrameDataProps = {
  // The collected data for one frame, grouped by goroutine.
  data: Row[];
  tableSchema: tableSchemaExt;
  schema: schemaInfo;
};

// FrameDataTable renders the captured data for a single frame; it's meant to be
// used when rendering a stack trace. The data can belong to a single goroutine
// or to many.
//
// We render one table per goroutine, with one row per variable.
export default function FrameDataTable({
  data,
  tableSchema,
  schema,
}: FrameDataProps) {
  // The number of frames for which to show data (each frame gets a table with
  // one row per variable). The default is 2.
  const [numShown, setNumShown] = useState(2);
  const snapshotState = useSnapshotState();
  const processResolver = useProcessResolver();

  if (!data || data.length == 0) {
    return <></>;
  }

  let showMore = numShown < data.length;
  data = data.slice(0, numShown);

  function goroutineIDStr(goroutineID: GoroutineId): string {
    const gs = processResolver.resolveGoroutineID(goroutineID);
    return `${gs.processFriendlyName} : ${gs.ID}`;
  }

  const linkIDs = new Set<string>();
  for (const frameData of data) {
    for (const l of frameData.Links) {
      linkIDs.add(l.LinkID);
    }
  }
  const {data: linkSpecs} = useQuery(GET_LINK_SPECS, {
    variables: {
      linkIDs: [...linkIDs],
    },
  });
  const linkSpecsMap = new Map<string, LinkSpec>();
  for (const l of linkSpecs?.getLinkSpecs || []) {
    linkSpecsMap.set(l.id, l);
  }

  type colData = {
    schema: ColumnInfo;
    value: string | null;
    links: resolvedLinksInfo[];
  };
  type goroutineData = {
    goroutineID: GoroutineId;
    procInfo: ProcessInfo;
    row: colData[];
  };

  function colIsHidden(ci: ColumnInfo): boolean {
    return (
      ci.Hidden ||
      // Also hide the goroutine_id column, because we're showing a button to
      // filter to this goroutine anyway.
      ci.Name == "goroutine_id"
    );
  }

  let allColumnsAreHidden = true;
  for (const ci of tableSchema.schema.Columns) {
    if (!colIsHidden(ci)) {
      allColumnsAreHidden = false;
      break;
    }
  }
  if (allColumnsAreHidden) {
    showMore = false;
  }

  // Go through the rows and join the columns schema with the data.
  const framesData: goroutineData[] = [];
  for (const row of data) {
    if (row.ColumnValues.length != tableSchema.schema.Columns.length) {
      console.log(
        "ColumnValues length does not match schema.Columns length",
        row.ColumnValues,
        tableSchema.schema.Columns,
      );
      throw new Error(
        "ColumnValues length does not match schema.Columns length",
      );
    }
    const zippedRow: colData[] = [];

    const linksByColumnIdx = linksByColumn(
      row.Links,
      tableSchema.schema.Columns,
      row.ColumnValues,
      tableSchema.schema.Links,
      schema,
    );

    for (let i = 0; i < row.ColumnValues.length; i++) {
      const colInfo: ColumnInfo = tableSchema.schema.Columns[i];
      if (colIsHidden(colInfo)) {
        continue;
      }
      zippedRow.push({
        schema: colInfo,
        value: row.ColumnValues[i],
        links: linksByColumnIdx[i],
      });
    }

    const goroutineID = parseGoroutineID(
      row.ColumnValues[tableSchema.goroutineIDColumnIdx]!,
    );

    framesData.push({
      goroutineID,
      procInfo: processResolver.resolveProcessIDOrThrow(goroutineID.ProcessID),
      row: zippedRow,
    });
  }

  return (
    <>
      {allColumnsAreHidden ? (
        <>All columns are hidden</>
      ) : (
        <>
          {framesData.map((frameData, idx) => (
            <TableContainer
              component={Paper}
              style={{verticalAlign: "top"}}
              key={idx}
            >
              <Table
                size="small"
                className={"frame-data-table"}
                style={{tableLayout: "auto"}}
              >
                <colgroup>
                  {/*Buttons*/}
                  <col width="20" />
                  {/*Expression*/}
                  <col />
                  {/*Value*/}
                  <col />
                </colgroup>
                <TableBody>
                  {/*Each column from frameData.row is rendered as a table row.*/}
                  {frameData.row.map((col, idx) => (
                    <TableRow key={col.schema.Name}>
                      {/*The first row for a goroutine gets a button that filters to that goroutine.*/}
                      <TableCell className={"filter-goroutine"} sx={{width: 5}}>
                        {idx == 0 && (
                          <Tooltip
                            title={
                              "Filter to goroutine " +
                              goroutineIDStr(frameData.goroutineID)
                            }
                          >
                            <IconButton
                              className={"filter-goroutine"}
                              style={{paddingTop: 0}}
                              component={Link}
                              to={snapshotState.goroutineURL(
                                processResolver.resolveProcessIDOrThrow(
                                  frameData.goroutineID.ProcessID,
                                ).ProcessID,
                                frameData.goroutineID.ID,
                              )}
                            >
                              <FilterAltIcon className={"filter-goroutine"} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          paddingLeft: 0,
                          verticalAlign: "top",
                          width: "200px",
                        }}
                      >
                        {col.schema.Name}
                      </TableCell>
                      <TableCell sx={{verticalAlign: "top"}}>
                        <MyTableCell
                          value={col.value}
                          name={col.schema.Name}
                          type={col.schema.Type}
                          links={col.links}
                          controller={snapshotState.tablesController}
                          processInfo={frameData.procInfo}
                          snapshotState={snapshotState}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ))}
        </>
      )}
      {showMore && (
        <Button onClick={() => setNumShown(numShown + 10)}>more…</Button>
      )}
    </>
  );
}

import {useQuery} from "@apollo/client";
import {Box, FormControl, MenuItem, Select} from "@mui/material";
import {gql} from "src/__generated__/gql";
import {
  FilteringOptionType,
  GoroutineStatusFilter,
  StacksFilter,
} from "src/__generated__/graphql";
import Filter from "@components/filter";
import {useSnapshotState} from "src/providers/snapshot-state";
import {ActiveFilterOption} from "@components/filter/Filter.tsx";
import {ScoredSuggestion} from "@components/filter/util.tsx";

const GET_FILTERING_OPTS = gql(/* GraphQL */ `
  query GetFilteringOptions($snapshotID: Int!) {
    filteringOptions(snapshotID: $snapshotID) {
      Category
      Package
      TypeName
      FuncName {
        Package
        Type
        Name
        QualifiedName
      }
      VarExpr
      BinaryID
      BinaryName
      ProgramName
      GoroutineStatus
    }
  }
`);

export interface ProcessForSelector {
  processID: number;
  processFriendlyName: string;
}

export type FilterBarProps = {
  snapshots: ProcessForSelector[];
  onInputChange: (value: string) => void;
};

export default function FilterBar(props: FilterBarProps) {
  const snapshotState = useSnapshotState();
  const {
    state: {filters, snapshotID},
  } = snapshotState;
  const showAllProcsOption = props.snapshots?.length > 1;
  const firstSnapshot = props.snapshots?.[0];
  const snapshotFilter = filters.find(
    (f) => f.Type == FilteringOptionType.ProcessSnapshot,
  );
  let procValue: number | undefined;
  if (snapshotFilter) {
    procValue = snapshotFilter.ProcessID!;
  } else {
    procValue = showAllProcsOption
      ? 0
      : firstSnapshot != undefined
        ? firstSnapshot.processID
        : undefined;
  }
  const {
    error: filterOptsError,
    data: filterOpts,
    loading: loadingFilterOpts,
  } = useQuery(GET_FILTERING_OPTS, {
    variables: {
      snapshotID,
    },
  });
  if (filterOptsError) {
    throw filterOptsError;
  }
  const onSelectedProcessChanged = (event) => {
    // Remove the existing filter on snapshot, if it exists.
    const newFilters = filters.filter(
      (f) => f.Type != FilteringOptionType.ProcessSnapshot,
    );
    // Add a new function filter, unless the "all" option was selected.
    const selectedProcessID = event.target.value as number;
    if (selectedProcessID != 0) {
      newFilters.push({
        Type: FilteringOptionType.ProcessSnapshot,
        ProcessID: selectedProcessID,
      });
    }
    snapshotState.setFilters(newFilters);
  };

  // Build separate lists of all the values that should be in the filter.
  // First are the filter, then the suggestions.
  const filterOptions: ActiveFilterOption[] = filters
    .filter((filter) => filter.Type != FilteringOptionType.ProcessSnapshot)
    .map((filter) => ({
      type: "synthetic",
      filter,
    }));

  // Add the focused nodes entry
  const {focusedNodes} = snapshotState.state;
  if (focusedNodes && focusedNodes?.length > 1) {
    filterOptions.push({
      type: "focusedNodes",
      focusedNodes,
    });
  }

  const suggestionOptions: ScoredSuggestion[] = (
    filterOpts?.filteringOptions || []
  ).map((filteringSuggestion) => new ScoredSuggestion(filteringSuggestion));

  const extraSuggestions: ScoredSuggestion[] = Object.values(
    GoroutineStatusFilter,
  ).map(
    (status: GoroutineStatusFilter) =>
      new ScoredSuggestion({
        Category: FilteringOptionType.GoroutineStatus,
        GoroutineStatus: status,
      }),
  );

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      flexGrow={1}
      alignItems={"stretch"}
      height={"100%"}
    >
      <FormControl
        sx={{mr: 1, ml: 1, minWidth: 120, height: "100%"}}
        margin="none"
      >
        <Select
          color="secondary"
          value={procValue}
          onChange={onSelectedProcessChanged}
          sx={{height: "100%"}}
        >
          {showAllProcsOption && (
            <MenuItem key={0} value={0}>
              All {props.snapshots?.length} processes.
            </MenuItem>
          )}
          {props.snapshots?.map((snapshot) => (
            <MenuItem key={snapshot.processID} value={snapshot.processID}>
              {snapshot.processFriendlyName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div style={{flexGrow: 1, height: "100%"}}>
        <Filter
          suggestions={[...suggestionOptions, ...extraSuggestions]}
          values={filterOptions}
          onChange={(values: ActiveFilterOption[]) => {
            const filters: StacksFilter[] = values
              .filter((f) => f.type == "synthetic")
              .map((f) => f.filter);
            // If there is a snapshot filter coming from the
            // dropdown, add it.
            if (snapshotFilter) {
              filters.push(snapshotFilter);
            }
            snapshotState.setFilters(filters);
            const shouldHaveFocusedNodes =
              values.find((value) => value.type == "focusedNodes") != null;
            if (
              !shouldHaveFocusedNodes &&
              focusedNodes &&
              focusedNodes.length > 1
            ) {
              focusedNodes.length = 0;
            }
            snapshotState.updateSearchParam({filters, focusedNodes});
          }}
          onInputChange={(value: string) => props.onInputChange(value)}
        />
      </div>
    </Box>
  );
}

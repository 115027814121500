import {SnapshotSpec} from "@graphql/graphql.ts";
import React from "react";
import {useApolloClient} from "@apollo/client";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {HelpCircle} from "@components/HelpCircle.tsx";
import {ADD_OR_UPDATE_TYPE_SPEC} from "@components/available-vars.tsx";
import AddIcon from "@mui/icons-material/Add";
import {IconEdit} from "@components/icons/IconEdit.tsx";

// MissingTypes renders the list of missing types for the spec and allows the
// user to create types specs for these types.
function MissingTypes(props: {
  spec: SnapshotSpec;
  onEditTypeClick: (typeName: string) => void;
}): React.JSX.Element {
  const client = useApolloClient();
  const missingTypes = props.spec.missingTypeQualifiedNames;

  if (!missingTypes.length) {
    return <></>;
  }

  const add = async (typeName: string) => {
    const {errors} = await client.mutate({
      mutation: ADD_OR_UPDATE_TYPE_SPEC,
      variables: {
        input: {
          typeQualifiedName: typeName,
          collectExprs: [],
          collectAll: true,
        },
      },
    });
    if (errors) {
      console.error("failed to update type spec", errors);
    }
  };

  return (
    <Stack>
      <Card
        sx={{
          position: "sticky",
          top: 0,
        }}
      >
        <CardHeader
          title={
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography variant="h2">Missing types</Typography>
              <HelpCircle
                tip={
                  '"Missing types" are types that were encoutered inside Go ' +
                  "interfaces while collecting snapshots. Values inside interfaces are only collected " +
                  'if there is a "type spec" for the value\'s type. The types listed here do not have ' +
                  "a type spec. A type spec for one of these types can be created by clicking the Add or Edit buttons."
                }
              />
            </Stack>
          }
        />

        <CardContent>
          <List
            sx={{
              maxHeight: "700px",
              overflowY: "auto",
              pr: 0.5,
            }}
          >
            {missingTypes.map((typeName) => (
              <Card
                key={typeName}
                sx={{
                  bgcolor: (theme) => theme.palette.background.drawer,
                  p: 2,
                  mb: 1,
                  display: "flex",
                }}
                square
              >
                <Typography
                  sx={{wordBreak: "break-word"}}
                  variant="body4"
                  color="secondary"
                  flexGrow={1}
                >
                  {typeName}
                </Typography>

                <Tooltip
                  title={
                    "Create a type spec for this type. By default, all of the type's fields will be collected."
                  }
                >
                  <IconButton onClick={() => add(typeName)} sx={{p: 0}}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title={"Edit the type spec for this type."}>
                  <IconButton
                    onClick={() => props.onEditTypeClick(typeName)}
                    sx={{p: 0, ml: 1}}
                  >
                    <IconEdit />
                  </IconButton>
                </Tooltip>
              </Card>
            ))}
          </List>
        </CardContent>
      </Card>
    </Stack>
  );
}

export default MissingTypes;

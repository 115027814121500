import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import IconSnapshots from "@components/icons/IconSnapshots.tsx";
import IconSpec from "@components/icons/IconSpec.tsx";
import IconAgents from "@components/icons/IconAgents.tsx";
import IconBinaries from "@components/icons/IconBinaries.tsx";
import IconSchedule from "@components/icons/IconSchedule.tsx";
import {pathComponent, ROUTER_PATHS} from "../../router/router-paths.ts";
import {MenuItem} from "./components/MenuItem.tsx";

export const menuItems: MenuItem[] = [
  {
    matcher: (location: string) =>
      location == "/" || location.includes(ROUTER_PATHS.snapshots),
    link: ROUTER_PATHS.snapshots,
    name: "Snapshots",
    icon: <IconSnapshots />,
  },
  {
    matcher: (location: string) =>
      location.includes(pathComponent(ROUTER_PATHS.enableEvents)) ||
      location.includes(pathComponent(ROUTER_PATHS.log)) ||
      location.includes(pathComponent(ROUTER_PATHS.liveLog)),
    link: ROUTER_PATHS.enableEvents,
    name: "Events",
    icon: <ManageSearchIcon sx={{color: "currentColor"}} />,
  },
  {
    matcher: (location: string) =>
      location.includes(ROUTER_PATHS.snapshotSpecEditor),
    link: ROUTER_PATHS.snapshotSpecEditor,
    name: "Spec",
    icon: <IconSpec />,
  },
  {
    matcher: (location: string) => location.includes(ROUTER_PATHS.agents),
    link: ROUTER_PATHS.agents,
    name: "Agents",
    icon: <IconAgents />,
  },
  {
    matcher: (location: string) => location.includes(ROUTER_PATHS.binaries),
    link: ROUTER_PATHS.binaries,
    name: "Binaries",
    icon: <IconBinaries />,
  },
  {
    matcher: (location: string) => location.includes(ROUTER_PATHS.schedule),
    link: ROUTER_PATHS.schedule,
    name: "Schedule",
    icon: <IconSchedule />,
  },
];

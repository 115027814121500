import {ProcessLabel} from "@graphql/graphql.ts";
import {getLabelValue, Label} from "@util/labels.ts";
import {AgentReportsResult} from "@providers/agent-reports-provider.tsx";
import {AllProcessesSelected, ProcessInfo} from "../@types.ts";
import {UNNAMED_ENV} from "src/constants/unnamed_env";
import {RawProcessSelection} from "./processSelection.ts";

// processHierarchy contains data about processes reported by agents. The
// processes are grouped by environment and program.
export class ProcessHierarchy {
  // Processes without an environment label are grouped under the key undefined
  // key.
  environments: Map<string | typeof UNNAMED_ENV, EnvironmentProcesses>;

  constructor(reports: AgentReportsResult["Reports"]) {
    this.environments = new Map<string, EnvironmentProcesses>();
    reports.forEach((report) => {
      report.Processes.forEach((process) => {
        let binaryID: string;
        let binaryName: string;
        let unknown: boolean;
        switch (process.Binary.__typename) {
          case "Binary":
            binaryID = process.Binary.id;
            binaryName = process.Binary.userName;
            unknown = false;
            break;
          case "UnknownBinaryInfo":
            binaryID = process.Binary.Hash;
            binaryName = process.Binary.SuggestedName;
            unknown = true;
            break;
        }

        const rawEnv = getLabelValue(Label.Environment, process.Labels);
        const env = rawEnv == undefined || rawEnv == "" ? UNNAMED_ENV : rawEnv;

        const pInfo = new ProcessInfo({
          host: {
            ip:
              report.IPAddresses.length > 0 ? report.IPAddresses[0] : undefined,
            hostname: report.Hostname,
          },
          processToken: process.ProcessToken,
          pid: process.PID,
          agentID: report.AgentID,
          processID: processName(process.Labels ?? [], process.PID),
          programName: getLabelValue(Label.Program, process.Labels)!,
          binary: {
            hash: binaryID,
            unknown: unknown,
            binaryName: binaryName,
          },
          environment: env,
        });

        this.addProcess(pInfo);
      });
    });
  }

  empty(): boolean {
    return this.environments.size == 0;
  }

  multipleEnvironments(): boolean {
    return this.environments.size > 1;
  }

  // singleEnvironment returns the single environment in the processHierarchy.
  // If the processHierarchy is empty, or if it has more than one environment,
  // it throws. A return value of undefined means that there are some processes,
  // but they all report no environment configured.
  singleEnvironment(): string | typeof UNNAMED_ENV {
    if (this.multipleEnvironments()) {
      throw new Error(
        "singleEnvironment() called on processHierarchy with multiple environments",
      );
    }
    if (this.empty()) {
      throw new Error("singleEnvironment() called on empty processHierarchy");
    }

    const cur = this.environments.keys().next();
    if (cur.done) {
      // We already verified above that the map is not empty. This check is here
      // to keep the type checker/linter happy; without it `cur.value` has type
      // `any`.
      throw new Error("unreachable");
    }
    return cur.value;
  }

  addProcess(process: ProcessInfo) {
    const env = process.environment;
    if (!this.environments.has(env)) {
      this.environments.set(env, new EnvironmentProcesses());
    }
    this.environments.get(env)!.addProcess(process);
  }

  processesForEnvAndProgram(
    env: string | typeof UNNAMED_ENV,
    program: string,
  ): ProcessInfo[] {
    const envProcs = this.environments.get(env);
    if (envProcs === undefined) {
      throw new Error(`environment missing: ${String(env)}`);
    }
    const procs = envProcs.programs.get(program);
    if (procs === undefined) {
      throw new Error(`program missing: ${program}`);
    }
    return procs;
  }

  defaultSelection(): RawProcessSelection | undefined {
    // We can initialize the selection only if there is a single environment (or
    // if environments are not used). If there are environments, the user first
    // needs to select one.
    if (this.multipleEnvironments()) {
      return undefined;
    }

    // If the report has no programs/processes, there can be no selection.
    if (this.empty()) {
      return undefined;
    }

    // If we got here, then there must be a single environment, so we can create a
    // default selection.

    // All programs in the report start off as selected.
    const singleEnvName = this.singleEnvironment();
    return defaultSelectionForEnvironment(this, singleEnvName);
  }
}

function processName(labels: ProcessLabel[], pid: number) {
  const id = labels.find((label) => label.Label == Label.PID.toString())?.Value;
  if (id !== undefined) {
    return id;
  }
  return pid.toString();
}

export function defaultSelectionForEnvironment(
  report: ProcessHierarchy,
  envName: string | typeof UNNAMED_ENV,
): RawProcessSelection {
  const env = report.environments.get(envName);
  if (env === undefined) {
    throw new Error(`environment missing: ${String(envName)}`);
  }
  // All programs in the report start off as selected.
  const programsSelection: [string, AllProcessesSelected][] = [];
  env.programs.forEach((_, program) => {
    programsSelection.push([program, {type: "all"}]);
  });
  return {
    environment: envName == UNNAMED_ENV ? null : envName,
    programsSelection: programsSelection,
  };
}

type reportDelta = {
  staleProcessesByProgram: Map<string, ProcessInfo[]>;
  newPrograms: string[];
};

class EnvironmentProcesses {
  programs: Map<string, ProcessInfo[]>;

  constructor() {
    this.programs = new Map<string, ProcessInfo[]>();
  }

  addProcess(process: ProcessInfo) {
    let procs = this.programs.get(process.programName);
    if (procs === undefined) {
      procs = [];
      this.programs.set(process.programName, procs);
    }
    procs.push(process);
  }

  hasProcess(program: string, processToken: string): boolean {
    return this.getProcess(program, processToken) != undefined;
  }

  getProcess(program: string, processToken: string): ProcessInfo | undefined {
    const procsForProgram = this.programs.get(program);
    if (procsForProgram === undefined) {
      return undefined;
    }
    return procsForProgram.find((v) => v.processToken == processToken);
  }

  // Returns the differences between `this` and `other`.
  delta(other: EnvironmentProcesses): reportDelta {
    const removedProcs = new Map<string, ProcessInfo[]>();
    Array.from(this.programs.keys()).forEach((program) => {
      const myProcs = this.programs.get(program)!;
      const otherProcs = other.programs.get(program);
      if (otherProcs === undefined) {
        removedProcs.set(program, myProcs);
      } else {
        removedProcs.set(
          program,
          myProcs.filter(
            (p) => !other.hasProcess(p.programName, p.processToken),
          ),
        );
      }
    });

    const addedPrograms = Array.from(other.programs.keys()).filter(
      (p) => !this.programs.has(p),
    );
    return {
      newPrograms: addedPrograms,
      staleProcessesByProgram: removedProcs,
    };
  }
}
